@import 'variables';
@import 'mixins/font-mixins';
@import 'mixins/media-mixins';
@import '@taiga-ui/core/styles/taiga-ui-local';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  font-family: var(--tui-text-font);
  width: 100%;
  height: 100%;
}

body {
  @include font-medium();
  margin: 0;
  color: $color-black;
  background-color: $color-background !important;
  position: fixed;
}

@media (max-width: $screen-md) {
  tui-root,
  .main {
    height: 100%;
  }
}

a {
  cursor: pointer;
}

audio,
video {
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 12px;
}

.typography {
  &_size {
    &_l {
      @include font-larger();
    }

    &_m {
      @include font-large();
    }
  }
}

.text {
  &_bold {
    font-weight: 800 !important;
  }

  &_truncate {
    @include font-truncate();
  }

  &_bold {
    font-weight: 800 !important;
  }

  &_heading {
    &-1 {
      font: var(--tui-font-heading-1);
    }
    &-2 {
      font: var(--tui-font-heading-2);
    }
    &-3 {
      font: var(--tui-font-heading-3);
    }
    &-4 {
      font: var(--tui-font-heading-4);
    }
    &-5 {
      font: var(--tui-font-heading-5);
    }
    &-6 {
      font: var(--tui-font-heading-6);
    }
  }

  &_size {
    &-xl {
      font: var(--tui-font-text-xl);
    }
    &-l {
      font: var(--tui-font-text-l);
    }
    &-l-2 {
      font: var(--tui-font-text-l-2);
    }
    &-m {
      font: var(--tui-font-text-m);
    }
    &-m-2 {
      font: var(--tui-font-text-m-2);
    }
    &-s {
      font: var(--tui-font-text-s);
    }
    &-s-2 {
      font: var(--tui-font-text-s-2);
    }
    &-xs {
      font: var(--tui-font-text-xs);
    }
  }

  &_color {
    &-primary {
      color: $color-text-primary;
    }
    &-secondary {
      color: $color-text-secondary;
    }
    &-accent {
      color: $color-accent-text;
    }
    &-01 {
      color: $color-text-01;
    }
    &-01-50 {
      color: $color-text-01-50;
    }
    &-02 {
      color: $color-text-02;
    }
    &-03 {
      color: $color-text-03;
    }
    &-04 {
      color: $color-text-04;
    }
    &-05 {
      color: $color-text-05;
    }
    &-06 {
      color: $color-text-06;
    }
    &-07 {
      color: $color-text-07;
    }
    &-08 {
      color: $color-text-08;
    }
    &-09 {
      color: $color-text-09;
    }
    &-grey {
      color: $color-text-grey;
    }
    &-card-name {
      color: $color-text-card-name;
    }
    &-badge {
      color: $color-text-badge;
    }
  }
}

// Material UI overrides

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $color-accent;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background-color: $color-secondary;
}

.mat-fab:not([class*='mat-elevation-z']),
.mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

mat-selection-list.mat-list-base {
  padding-top: 0;
}

@include media-down($screen-sm-max) {
  .organisation__list .organisation-list .mat-list-item.mat-list-option.organisation-list__item .mat-list-text {
    padding-right: 0;
  }
}

.mat-list-item,
.mat-list-option {
  font-family: var(--tui-text-font);
}

.mat-list-option {
  &:hover {
    background: $color-selected;
  }
}

.mat-list-single-selected-option {
  background: $color-selected !important;

  .list-item {
    &__text {
      color: $color-text-list-item-text-active !important;
    }

    &__title {
      color: $color-text-list-item-title-active !important;
    }
  }

  .menu-item {
    &__icon {
      color: $color-menu-item-icon-active !important;
    }
  }

  .settings-list-item {
    &__icon {
      color: $color-navigation-item-icon-active;
    }
  }
}

.mat-list-base .mat-list-item {
  color: $color-black;
}

// Taiga UI overrides

// TODO: taiga bug fix https://redmine.tbnsoft.ru/issues/11025#note-11
.t-tags_empty.t-with-placeholder {
  height: 0;
}

.tui-skeleton:after {
  border-radius: 12px;
}

.tui-skeleton_rounded:after {
  border-radius: inherit;
}

tui-dropdown {
  z-index: 9999999 !important;
}

tui-accordion tui-accordion-item tui-expand .t-content.ng-star-inserted,
tui-accordion-item tui-expand .t-content.ng-star-inserted,
tui-accordion tui-accordion-item tui-expand tui-loader fieldset .t-content.ng-star-inserted,
tui-accordion-item tui-expand tui-loader fieldset .t-content.ng-star-inserted {
  padding: 0;
}

tui-accordion-item {
  &.align-items-start {
    .t-header {
      align-items: flex-start !important;
    }
  }

  &.padding-small {
    .t-header {
      padding: 0.75rem;
    }
  }
}

tui-carousel .t-scroller .t-wrapper fieldset {
  padding: 0;
}

tui-carousel .t-scroller {
  background-color: $color-white;
}

[tuiWrapper][data-appearance='outline-blue'] {
  background-color: transparent;
  color: $color-accent;
  border: 1px solid;
  border-radius: var(--tui-radius-m);
}

[tuiWrapper][data-appearance='outline'].wrapper .content {
  color: $color-white;
}

[tuiWrapper][data-appearance='grey-button'] {
  background: $color-grey-button;
  color: $color-default;

  .wrapper-hovered {
    background: $color-grey-light;
  }

  .wrapper-pressed {
    background: $color-grey;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='white-button'] {
  background: $color-white;
  color: $color-accent;

  .wrapper-hovered {
    background: $color-grey-light;
  }

  .wrapper-pressed {
    background: $color-grey;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='lp-icon'] {
  color: $color-icon;
  background: none;
  border: none;

  .wrapper-hover {
    background: $color-wrapper-hover;
  }

  .wrapper-pressed {
    background: $color-wrapper-pressed;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='lp-icon-filled-light'] {
  color: $color-icon-light;
  background: none;
  border: none;

  tui-svg {
    fill: $color-icon-light;
  }

  .wrapper-hover {
    background: $color-wrapper-hover;
  }

  .wrapper-pressed {
    background: $color-wrapper-pressed;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='lp-icon-filled-pink'] {
  color: $color-pink;
  background: none;
  border: none;

  tui-svg {
    fill: $color-pink;
  }

  .wrapper-hover {
    background: $color-wrapper-hover;
  }

  .wrapper-pressed {
    background: $color-wrapper-pressed;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='lp-icon-block'] {
  color: $color-icon;
  background: $color-white;
  border: 1px solid $color-grey-light;

  .wrapper-hover {
    background: $color-wrapper-hover;
  }

  .wrapper-pressed {
    background: $color-wrapper-pressed;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='lp-icon-block-filled-light'] {
  color: $color-icon-light;
  background: $color-white;
  border: 1px solid $color-grey-light;

  tui-svg {
    fill: $color-icon-light;
  }

  .wrapper-hover {
    background: $color-wrapper-hover;
  }

  .wrapper-pressed {
    background: $color-wrapper-pressed;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='lp-icon-block-filled-pink'] {
  color: $color-pink;
  background: $color-white;
  border: 1px solid $color-grey-light;

  tui-svg {
    fill: $color-pink;
  }

  .wrapper-hover {
    background: $color-wrapper-hover;
  }

  .wrapper-pressed {
    background: $color-wrapper-pressed;
  }

  .wrapper-disabled {
    color: $color-grey-light;
  }
}

[tuiWrapper][data-appearance='active-icon'] {
  background: transparent;
  color: $color-accent-65;
}

[tuiWrapper][data-appearance='textfield'][data-state='readonly'],
tui-checkbox-block._readonly [tuiWrapper][data-appearance='whiteblock'] {
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-radius: 0;
  padding: 0 !important;

  &:after {
    border-top: none;
    border-right: none;
    border-bottom-width: 2px;
    border-bottom-style: dotted;
    border-left: none;
    border-radius: 0;
  }

  &._focused:not(._label-outside) .placeholder {
    color: $color-text-02;
  }

  textarea,
  .input,
  .t-input {
    border: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .content,
  .t-content,
  .t-content .t-placeholder {
    padding: 0 5px 0 0 !important;
  }
}

tui-checkbox-block.white-space-normal .t-content {
  padding: 0.5625rem 0.75rem 0.5625rem 1rem !important;

  & .t-view {
    margin: 0 !important;
    align-self: center;
  }

  & .t-label {
    white-space: normal;
  }
}

tui-checkbox-labeled.margin-auto tui-checkbox {
  margin: auto;
}

[tuiWrapper][data-appearance='textfield'][data-state='readonly'] .t-content .t-icon,
tui-multi-select._readonly .wrapper .t-wrapper .t-arrow {
  display: none;
}

tui-multi-select-option {
  max-width: 100%;
}

[tuiWrapper][data-appearance='whiteblock'] .label,
[tuiWrapper][data-appearance='whiteblock-active'] .label {
  color: $color-text-02;
}

[tuiWrapper][data-appearance='link'] {
  color: $color-accent;

  &[data-state='hovered'] {
    color: $color-link-new-color-base-09-10;
  }

  &[data-state='pressed'] {
    color: $color-link-new-color-base-09-20;
  }

  .t-left {
    margin: 0 !important;
  }
}

[tuiWrapper] {
  &[data-appearance='textfield'] {
    &[data-mode='onDark'] {
      background: $color-field;

      &._focused {
        &._focused {
          background: $color-white-8;

          &:after {
            color: $color-focus;
          }
        }
      }

      &:hover:not(._no-hover) {
        background: $color-field-hover;
      }

      &:after {
        color: transparent;
      }
    }

    &[data-state='readonly'] {
      &:after {
        color: $color-base-04 !important;
      }
    }
  }

  &[data-appearance='secondary'] {
    &[data-mode='onDark'] {
      background: $color-field;

      &:hover:not(._no-hover) {
        background: $color-field-hover;
      }
    }
  }
}

tui-textarea {
  &.field_height_min {
    min-height: 2.5rem !important;
  }
}

tui-editor {
  &.textarea {
    min-height: 2.5rem !important;

    .t-wrapper {
      .t-toolbar-wrapper {
        .t-placeholder {
          position: absolute;
          margin-top: 0.825rem;

          &_raised {
            display: none;
          }
        }
      }

      .t-scrollbar {
        padding-bottom: 0;
        max-height: 200px;

        .t-content {
          .tui-editor-socket {
            .ProseMirror {
              padding-top: 0.675rem;
              min-height: 2.5rem;
              font: var(--tui-font-text-s);
            }
          }
        }
      }
    }

    &__toolbar_hidden {
      .t-wrapper {
        .t-toolbar-wrapper {
          visibility: hidden;
          position: absolute;
          top: -48px;
        }
      }
    }
  }
}

.t-dialog:last-of-type .t-close {
  position: absolute;
  top: 8px;
  right: 8px;
}

.view [tuiWrapper][data-appearance='textfield'][data-state='readonly'] {
  border-radius: $field-radius;

  &:after {
    border-bottom: none;
  }

  .t-input {
    cursor: default;
    padding-left: 12px !important;
  }

  .t-content {
    cursor: default;
  }
}

.edit [tuiWrapper][data-appearance='textfield'] {
  border-radius: $field-radius;
}

tui-input-file {
  display: flex !important;
  flex-direction: column;

  &.size {
    &_m {
      & [tuiWrapper] {
        --tui-height-l: var(--tui-height-s) !important;
        padding: 0 !important;
      }

      & [tuiWrapper] label {
        @include font-medium();
      }
    }
  }

  .preview_big {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
}

tui-files {
  tui-file {
    background-color: inherit !important;
  }
}

tui-file {
  border: 1px dashed $color-base-06 !important;

  .t-text {
    color: $color-base-06 !important;
  }

  &.hide-remove-button {
    & button {
      display: none;
    }
  }

  &.disabled {
    color: $color-disabled;

    & svg {
      color: $color-disabled;
    }
  }

  &.size {
    &_s {
      font: var(--tui-font-text-s);
    }
    &_m {
      font: var(--tui-font-text-s);
    }
    &_l {
      font: var(--tui-font-text-m);
    }
  }
}

tui-input-files {
  [tuiWrapper][data-appearance='input-file']:after {
    border: 1px dashed $color-base-06;
  }

  &.invalid {
    [tuiWrapper][data-appearance='input-file']._mobile:after,
    [tuiWrapper][data-appearance='input-file']:after {
      border: 1px solid $color-red;
    }
  }

  &.size {
    &_m {
      min-height: var(--tui-height-m);

      & [tuiWrapper] {
        --tui-height-l: var(--tui-height-m) !important;
        padding: 0 !important;
      }

      & [tuiWrapper] label {
        @include font-medium();
      }
    }
  }

  .t-description {
    padding: 0.5rem 0 !important;

    a {
      span {
        color: var(--tui-text-02);
      }
    }

    span {
      color: var(--tui-text-03);
    }
  }
}

tui-input-phone-international.invalid {
  .t-input-phone {
    outline: 1px solid $color-error;
  }
}

tui-hint-box {
  --tui-height-m: 0;
  --tui-primary: #{$color-tooltip-background};
  --tui-radius-l: 4px;

  padding: 8px 12px !important;

  &::before {
    display: none;
  }
}

tui-marker-icon[data-size='s'].icon {
  width: 2rem;
  height: 2rem;
}

tui-notification[data-tui-host-status='info']::after {
  background-color: $color-status-info-bg-hover !important;
}

tui-notification[data-tui-host-status='warning']::after {
  background-color: $color-status-warning-bg-hover !important;
}

tui-legend-item {
  button {
    width: 100%;

    .t-wrapper {
      .t-content {
        justify-content: start;
      }
    }
  }
}

form tui-input.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-month.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-month-range.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-inline.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-count.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-card.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-expire.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-cvc.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-number.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-password ber.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-range.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-slider.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-tag.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-date.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-date-time.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-date-range.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-time.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-phone.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-phone-international.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-textarea.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-primitive-textfield.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-select.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-multi-select.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-file.ng-touched.ng-invalid [tuiWrapper]:after,
form tui-input-file.ng-touched.ng-invalid [tuiWrapper] label a {
  color: $color-error-fill;
}

[tuiWrapper][data-appearance='positive'] {
  background: $color-green-color-white-10;
  color: $color-accent-text;

  @include wrapper-hover() {
    background: $color-green-color-color-base-09-10;
  }

  @include wrapper-active() {
    background: $color-green-color-color-base-09-20;
  }

  @include wrapper-disabled() {
    background: $color-disabled;
  }
}

:root {
  // TODO: add global variables
  --unions-color-background: #ffffff;
  --unions-color-additional-background: #f4f4f4;
  --unions-color-background-new: #f6f6f6;
  --unions-color-page-background: #fbfbfb;
  --unions-color-header-background: #f4f4f4;
  --unions-color-tooltip-background: #97a7b0;
  --unions-color-lp-product-background: #e3f5ff;
  --unions-color-lp-product-archive-background: #ffe3c2;
  --unions-color-lp-dialog-background: #4ac99b1f;
  --unions-color-lp-product-wizard-current-background: #42befe33;
  --unions-color-count-icon: #ddedfd;
  --unions-color-wrapper-pressed: #c9d6e5;
  --unions-color-textfield-background: #767676;
  --unions-color-shadow: #091e42;
  --unions-color-button-default: #efefef;
  --unions-color-grey-button: #f5f5f5;
  --unions-color-icon: #a3a5b0;
  --unions-color-icon-light: #e0e0e0;
  --unions-color-separator: #3c3c4322;
  --unions-color-field: #2c2c2c;
  --unions-color-field-hover: #333333;
  --unions-color-list-item-hover: #0000000a;
  --unions-color-list-item-active: #0000001f;
  --unions-color-text-list-item-title-active: #4c4c4c;
  --unions-color-text-list-item-text-active: #919191;
  --unions-color-menu-item-icon-active: #2683c6;
  --unions-color-navigation-item-icon-active: #919191;
  --unions-color-text-navigation-item-active: #ffffff;
  --unions-color-navigation-active: #ffffff;

  --unions-color-scroll: #bbc1c7;
  --unions-color-dictionary-icon: #ff8c67;
  --unions-color-selected: #d4e5f4;
  --unions-color-active: #{darken(#d4e5f4, 4%)};
  --unions-color-hover: #dddddd;

  --unions-color-text-primary: #596275;
  --unions-color-text-secondary: #7a8ea7;
  --unions-color-text-01: #191c30;
  --unions-color-text-04: #1b1f3ba6;
  --unions-color-text-04-40: #1b1f3b66;
  --unions-color-text-05: #292929;
  --unions-color-text-06: #fe8800;
  --unions-color-text-07: #fe424d;
  --unions-color-text-08: #6b778c;
  --unions-color-text-09: #3c3c43;
  --unions-color-text-grey: #9cabbd;
  --unions-color-text-card-name: #3b4d60;
  --unions-color-text-badge: #ffffff;
  --unions-color-text-message-meta: #2683c6;

  --unions-color-default: #4a5e72;
  --unions-color-primary: #41bbca;
  --unions-color-primary-pale: #98e2ff;
  --unions-color-primary-light: #e8ffff;
  --unions-color-disabled: #bdbdbd;
  --unions-color-link: #73bce6;
  --unions-color-link-command: #52a1ef;
  --unions-color-link-02: #526ed3;
  --unions-color-error: #ff8c67;
  --unions-color-none-background: #a8cef1;
  --unions-color-grade-popular: #7b439e;
  --unions-color-grade-notable: #2fad96;

  --unions-color-grey: #9cabbd;
  --unions-color-grey-dark: #919191;
  --unions-color-day-base-05: #b0b0b0;

  --unions-color-red: #ff0000;
  --unions-color-green: #4fae4e;
  --unions-color-green-dark: #3c7c3c;
  --unions-color-blue: #2683c6;
  --unions-color-blue-dark: #3f51b5;
  --unions-color-blue-light: #419fd9;
  --unions-color-pink: #ff2055;
  --unions-color-orange: #fec685;
  --unions-color-support-08: #ff8a00;
  --unions-color-support-22: #0476bd;
  --unions-color-support-23: #fcbb14;
  --unions-color-support-24: #ff7043;

  --unions-color-wrapper-hover: #daebfa;

  --unions-color-mute: #c0d5e0;
  --unions-color-status-disabled: #f4f4f4;

  --unions-color-yellow-24: #ffc7003d;
  --unions-color-list-hover: #0000000a;
  --unions-color-list-selected: #0000001f;

  // TODO: for change styles Taiga UI
  --tui-primary: #42befe;
  --tui-primary-hover: #{darken(#42befe, 10%)};
  --tui-primary-active: #{darken(#42befe, 20%)};

  --tui-accent: #eb595e;
  --tui-accent-hover: #{darken(#eb595e, 10%)};
  --tui-accent-active: #{darken(#eb595e, 20%)};

  --tui-text-01: #4c4c4c;
  --tui-focus: #42befe;
  --tui-font-text-xxs: normal 0.5625rem/1rem var(--tui-font-text);

  // TODO: Удалить после решения проблемы с работой color-mix в старых версиях ОС/браузера #10142
  --unions-color-shadow-8: #091e4214;
  --unions-color-textfield-background-12: #7676801f;
  --unions-color-text-09-60: #3c3c4399;
  --unions-color-base-09-5: #0000000d;
  --unions-color-base-09-12: #0000001f;
  --unions-color-base-09-50: #00000080;
  --unions-color-day-base-05: #b0b0b066;
  --unions-color-grey-20: #9cabbd33;
  --unions-color-day-base-05-40: #b0b0b066;
  --unions-color-grade-popular-20: #7b439e33;
  --unions-color-grade-notable-20: #2fad9633;
  --unions-color-support-24-20: #ff704333;
  --unions-color-text-07-12: #fe424d1f;
  --unions-color-accent-45: #42befe73;
  --unions-color-accent-65: #42befea6;
  --unions-color-accent-70: #42befeb3;
  --unions-color-black-30: #4c4c4c4d;
  --unions-color-black-40: #4c4c4c66;
  --unions-color-grey-light-25: #ededed40;
  --unions-color-blue-30: #2683c64d;
  --unions-color-support-22-30: #0476bd4d;
  --unions-color-support-22-30: #0476bd80;
  --unions-color-grey-75: #9cabbdbf;
  --unions-color-grey-85: #9cabbdd9;
  --unions-color-text-01-50: #191c3080;
  --unions-color-text-01-90: #191c30e6;
  --unions-color-tooltip-background-90: #97a7b0e6;
  --unions-color-white-8: #ffffff14;
  --unions-color-white-color-base-09-5: #f2f2f2;
  --unions-color-white-color-base-09-14: #dbdbdb;
  --unions-color-link-new-color-base-09-10: #4a63be;
  --unions-color-link-new-color-base-09-20: #4258a9;
  --unions-color-green-color-white-10: #61b660;
  --unions-color-green-color-color-base-09-10: #479d46;
  --unions-color-green-color-color-base-09-20: #3f8b3e;
}

[tuiWrapper][data-appearance='textfield'] {
  box-shadow: none;
}

lucide-icon {
  display: flex;
  align-items: center;
}
